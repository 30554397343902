import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import ArrowRight from "../components/svg/arrowRight"

const Cta = ({ text }) => (
    <>
        <section className="bg cta-wrapper">
            <div className="container">
                <div className="row">
                    <div className="cols flex flex-wrap flex-sb flex-ac">
                        <motion.div className="col-67"
                            initial={{ scale: 0.9, y: 80 }}
                            whileInView={{ scale: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeInOut", duration: 0.5 }}
                        >
                            <h6>Let's chat</h6>
                            <p>{text}</p>
                        </motion.div>
                        <div className="col-26 cta-btn-wrapper">
                            <motion.div 
                                initial={{ scale: 0.5, x: -100 }}
                                whileInView={{ scale: 1, x: 0 }}
                                viewport={{ once: true }}
                                transition={{ ease: "easeOut", duration: 0.875 }}
                            >
                                <Link to="/contact/" className="btn btn-lg btn-purple"
                                >
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>
    </>
)

export default Cta